* {
  padding: 0;
  margin: 0;
}
html,
body,
#root {
  min-height: 100%;
  height: 100%;
}

.reset-password {
  display: flex;
  widtH: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  h2 {
    margin-bottom: .5rem;
  }

  &-form {
    width: 400px;
    border: 1px solid #000;
    padding: 1rem;
    border-radius: 9px;
    position:relative;
  }

  .links {
    text-align: center;
    margin-bottom: 1rem;
  }
}