.modalContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;

  .content{
    padding: 0;
    display: flex;
    flex: 1;

    > * {
      margin-right: 1rem;
    }

    .sidebar {
      max-width: 100%;
      // width: 10%;
      border-right: 1px solid #ccc;

      &__header{
        margin-bottom: 10px;
        font-size: 0.7rem;
      }

      &__content{
        padding: 0 1rem 0 0;
        max-height: 100%;
        overflow-y: auto;

        .mediaBlock{
          width: fit-content;
          cursor: pointer;
          position: relative;
          margin-bottom: 20px;
          border: 5px solid #d9d9d9;
          display: flex;
          padding: 5px;

          .media {
            width: auto;
            height: auto;
            max-height: 100px;
            max-width: 100px;
            position: relative;
            margin: auto;
          }
        }

        .active{
          border: 5px solid #00adff;
        }
      }
    }
    .info {
      flex: 1;
      display: flex;
      flex-direction: column;
      font-size: 0.8rem;
      &__content{
        padding: 0 1rem 0 0;
        display: flex;
        align-items: center;
        // justify-content: space-between;
        // flex-wrap: wrap;
      }
    }
    .input > * {
      font-size: 0.8rem;
    }

    .collage {
      overflow-y: hidden;
    }

    .mainContentBlock{
      // align-self: center;
      display: flex;
      flex-direction: column;
      // flex: 1;
      align-items: flex-start;
      width: 30%;
      position: relative;
      > img, video {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: auto;
        margin: 0;
      }
      .openButton {
        margin-top: 0.5rem
      }
    }

    .playArrow {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.5);;
      border-radius: 50%;
      padding: 10px;
      font-size: 30px;
      color: white;
      height: 50px;
      width: 50px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
  }

  .footer {
    border-top: 1px solid #d9d9d9;
    font-size: 0.8rem;
    max-height: 25%;

    .info{
      display: flex;
      flex-wrap: wrap;
      max-width: 100%;

      > div{
        padding: 5px 5px 5px 0;
        width: 100%;
        max-width: 50%;

        > strong{
          margin-right: 5px;
        }
      }
    }
  }
}
