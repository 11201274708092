.wrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.app_wrapper {
    max-width: 100%;
    width: 100%;
    display: flex;
    flex: 1;
    overflow-y: auto;
    &__content {
        flex: 4;
        width: 80%;
        position: relative;
        // background-color: #f3f3f3;
        &__page {
            margin: 0;
            padding: 0.8rem 1rem;
            background-color: #f5f5f5;
            
        }
    }
}
