@import 'src/css/media-mixin';

.preview {
  & > span > &__wrap {
    display: flex;
    @include _979 {
      flex-direction: column;
    }
  }
  &__wrap_title {
      padding: 3px 0;
      margin-right: 15px;
      width: 100%;
      display: block;
      max-width: 50%;
      @include _979 {
        max-width: fit-content;
      }
  }
}

.btn > div {
  padding: 0;
}
