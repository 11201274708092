.map-form-wrap {
  padding: 1.5rem;

  h2 {
    font-size: 1.1rem;
    border: 0;
    vertical-align: baseline;
    margin-bottom: .7rem;
  }

  &__container {
    .map-container{
      margin: 0;
      padding: 0;
      position: relative;
      min-height: 400px;
      display: flex;
      border: 1px solid #585858;
      margin-bottom: 1rem;
    }
  }

  .toolTipWrap {
    display: flex;
    flex-direction: column;
    padding: .3rem .5rem;

    .toolTipTitle {
      font-size: 1rem;
    }
  }
}
