.title {
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    margin: 0;
    padding: 0;
    margin-bottom: 0.5rem;
}

.map_container {
    margin: 0 1rem 1rem;
    padding: 0;
    position: relative;
    min-height: 400px;
    display: flex;
    border: 1px solid #585858;
}
.map_height100 {
    height: 100%;
}

.map_container > div {
    min-height: 100%;
    width: 100%;
    height: 400px;
}

.bottom {
    border: 0;
    margin: 0;
    padding: 1rem 0 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.buttons {
    border: 0;
    margin: 0;
    display: flex;
    justify-content: flex-end;
    flex: 0 0 auto;
}

.btn1 {
    border-radius: 5px !important;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    outline: none;
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
    cursor: pointer;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn2 {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    outline: none;
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
    cursor: pointer;
    margin-right: 1rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border-radius: 5px !important;
}

.custom_select {
    display: inline-block;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background: #fff
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
        no-repeat right 0.75rem center/8px 10px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    appearance: none;
    cursor: pointer;
}

.btn3 {
    font-weight: 400;
    text-align: center;
    user-select: none;
    border: 1px solid transparent;
    outline: none;
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    display: block;
    cursor: pointer;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
