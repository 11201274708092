.filterItem{
  display: flex;
  align-items: center;
  .field{
    width: 85%;
  }
  .checkText{
    font-weight: 500;
    margin-left: 10px;
    font-size: 0.8rem;
  }

  .btn{
    // margin-left: 10px;
    min-width: 40px;
    height: 40px;
    align-self: self-start;
    color: green;
  }
}

.textFiledWithoutArrows {
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input[type='number'],
  input[type="number"]:hover,
  input[type="number"]:focus {
    appearance: none;
    -moz-appearance: textfield;
  }
}
.autocomplete {
  .input {
    padding-right: 30px !important;
  }
  .adornment {
    position: absolute;
    right: 6px;
  }
}
