.card {
    width: 100%;
    background-color: #fff;
    border-radius: 6px;
    .header {
        background-color:  #3bbeff;
        font-size: 18px;
        color: #fff;
        padding: 1rem;
        line-height: 1.1;
        border-radius: 6px 6px 0 0;
    }
    .data {
        padding: 1rem
    }
}

.filterWrap {
    text-align: left;
    display: block;
    width: auto;

    .filterItem {
        margin-bottom: 1rem;
        display: flex;

        & > div:nth-of-type(1) {
            margin-right: 1rem;
        }
    }
}
