$opacityGray: rgba(0, 0, 0, 0.12);

.cameras {
  &__item {
    border-bottom: 1px solid $opacityGray;
  }
  &__wrap {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-bottom: 20px;
    margin-bottom: 30px;
    border-bottom: 1px solid $opacityGray;
  }
  &__float {
    padding: 0 16px;
  }
  &__head {
    & > span {
      display: flex;
      justify-content: space-between;
    }
    &__left {
      display: flex;
      width: 100%;
    }
    &__right {
      display: flex;
    }
    &__title {
      &:nth-last-of-type(1) {
        margin-right: 20px;
      }
      &:nth-last-of-type(2) {
        max-width: 270px;
        width: 100%;
      }
    }
  }
  &__pagination {
    padding: 20px 0;
  }
  &__collapse > div > div {
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
  }
  &__modal {
    &__form {
      padding: 20px;
      display: flex;
      flex-direction: column;
      .input {
          margin-bottom: 1rem;
      }
    }
    &__button {
      display: flex;
      justify-content: flex-end;
      padding: 0 24px 15px 24px;
      margin: 30px 0 15px 0;
      & > button {
        &:first-child {
          margin-right: 20px;
        }
      }
    }
  }
}

.card_block {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.card_line {
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
  justify-content: space-between;
}
.card {
  background: rgba(0, 0, 0, 0.12);
  flex: 1;
}
.card_field {
  flex: 1;
  &:not(:last-child) {
      margin-right: 1rem;
  }
}
.form_button {
  align-self: flex-start;
}
