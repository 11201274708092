@mixin _1170 {
  @media (max-width: 1170px) {
    @content;
  }
}

@mixin _1024 {
  @media (max-width: 1024px) {
    @content;
  }
}

@mixin _979 {
  @media (max-width: 979px) {
    @content;
  }
}

@mixin _768 {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin _860 {
  @media (max-width: 860px) {
    @content;
  }
}

@mixin _600 {
  @media (max-width: 600px) {
    @content;
  }
}

@mixin _480 {
  @media (max-width: 480px) {
    @content;
  }
}

@mixin _350 {
  @media (max-width: 350px) {
    @content;
  }
}

