.menu {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0 !important;

  &__list {
	padding-top: 0 !important;
  }
}
