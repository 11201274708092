$borderColor: rgba(0, 0, 0, 0.12);

.edit {
  width: 100%;
  max-width: 500px;
  border: 1px solid #000;
  padding: 0 40px;
  border-radius: 10px;
  & > h1 {
    padding-top: 15px;
  }
  & > h3 {
    padding: 20px 0;
  }
  &__wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
  &__form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 30px;
    &__wrap {
      display: flex;
      flex-direction: column;
      &__field {
        width: 100%;
        &:last-child {
          margin-top: 10px;
        }
      }
    }
    &__submit {
      padding-top: 20px;
      display: flex;
      justify-content: flex-end;
    }
  }
  &__btn {
    float: right;
    width: 100%;
    &:first-child {
      margin-right: 20px;
    }
  }
  &__profile {
    padding: 30px 0;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $borderColor;
    &__wrap {
      display: flex;
      flex-direction: column;
      padding-left: 15px;
    }
  }
}
