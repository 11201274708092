.textFiledWithoutArrows {
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type='number'],
  input[type="number"]:hover,
  input[type="number"]:focus {
    appearance: none;
    -moz-appearance: textfield;
  }
}

.info_block{
  padding-right: 15px;
  &__wrap_org {
    display: flex;
    flex-direction: column;
    &_item {
      display: flex;
    }
  }
  &__item{
    display: flex;
    flex-wrap: wrap;
    span {
      margin-right: 5px;
    }
  }
}

.container {
  margin: 1.5rem;
}