.container {
    margin: 1rem;
}

.title {
    cursor: pointer;

    &__item {
        margin-right: 10px;
    }

    &__item_bold {
        font-weight: 600;
        margin-right: 5px;
    }
}

.textFiledWithoutArrows {
    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
    input[type='number'],
    input[type='number']:hover,
    input[type='number']:focus {
        appearance: none;
        -moz-appearance: textfield;
    }
}

.colorWarning {
    color: #f44336;
}

.iconWrapper {
    display: inline-block;
    margin-right: 8px;
    img {
        margin-bottom: -.25rem;
    }
}

.fa_icon {
  font-size: 20px;
  line-height: normal;
  color: #aaaaaa;
}

.fa_icon_color {
    --fa-primary-color: #d32f2f;
    --fa-secondary-color: #aaaaaa;
}

.openComplex {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
}
