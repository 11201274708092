.wanted-message {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  .img {
    width: 70px;
    margin-right: 1rem;

    img {
      width: 100%;
      height: auto;
    }
  }

  .text {}
}