.table {
  &-information {
    border-spacing: 0px;
    border-collapse: collapse;
    width: 100%;
    
    th:first-child,
    td:first-child {
      padding-left: 15px;
    }
    th:last-child,
    td:last-child {
      padding-right: 15px;
    }
    &:nth-of-type(2n + 1) {
      td {
        background: #f5f5f5;
      }
    }
    td {
      padding: 5px 7px;
      font-size: 0.85rem;
    }
    th {
      background: #e2e6ea;
      padding: 9px 7px;
      font-size: 0.85rem;
      font-weight: 700;
      text-align: left;
    }
  }
}