.info_block{
  display: flex;
  flex: 1;
  &__header {
    font-weight: bold;
  }
  &__item{
    width: 12%;
    margin-right: 2%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    &_first {
      width: 1%;
    }
  }
  &__statuses {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

    &.selected {
        font-weight: 700;
    }
}
