.date_range__item {
  flex: 1 1;
  @media (max-width: 900px) {
    width: 100%;
    &:first-child {
      margin-bottom: 0.5rem;
    }
  }
}

