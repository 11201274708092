.header_stats_card {
  box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
}

.item_card {
  height: 100%;
  box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
  position: relative;
  overflow: visible;

  .title {
    background: #fff;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  .card_content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .map_container {
    width: 35%;
    height: 200px;
  }
}

.no_map_wrapper {
  width: 100%;
  height: 100%;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
  text-align: center;
  display: flex;
  align-items: center;
  h4 {
    color: darkred;
  }
}