.loading_content {
    &.absolute {
        position: absolute;
        z-index: 900;
        left: 0;
        right: 0;
    }
    &.bottom {
        bottom: 0;
    }
    &.top {
        top: 0;
    }
    &.fill {
        top: 0;
        background: rgba(0,0,0,.1);
        .loading_svg {
            position: absolute;
            left: 50%;
            top: 50%;
            margin: -20px 0 0 -20px;
        }
    }
    &.center {
        text-align: center;
    }
    &.padding {
        padding: 1rem;
    }
}


.loading_svg {
    transition: all 0.5s linear;
    animation: loading_svg 1.5s linear infinite;
}
@keyframes loading_svg {
    from {
        transform: rotate(-360deg);
    }
}

.loading_linear {
    background-color: rgb(167, 202, 237);
    height: 4px;
    overflow: hidden;
    position: relative;

    &__bar_top {
        width: auto;
        animation: loading_linear__bar_top 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
        background-color: #1976d2;
        top: 0;
        left: 0;
        width: 100%;
        bottom: 0;
        position: absolute;
        transition: transform 0.2s linear;
        transform-origin: left;
        transition-delay: 0s;
    }

    &__bar_bottom {
        width: auto;
        animation: loading_linear__bar_bottom 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) 1.15s infinite;
        background-color: #1976d2;
        top: 0;
        left: -200%;
        width: 100%;
        bottom: 0;
        position: absolute;
        transition: transform 0.2s linear;
        transform-origin: left;
        transition-delay: 0s;
    }
}

@keyframes loading_linear__bar_top {
    0% {
        left: -35%;
        right: 100%;
    }

    60% {
        left: 100%;
        right: -90%;
    }
    100% {
        left: 100%;
        right: -90%;
    }
}

@keyframes loading_linear__bar_bottom {
    0% {
        left: -200%;
        right: 100%;
    }
    60% {
        left: 107%;
        right: -8%;
    }
    100% {
        left: 107%;
        right: -8%;
    }
}