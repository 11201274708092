.wrapper {
    width: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    margin: 0 0 10px;
}

.list_item {
    border-bottom: 1px solid #d9d9d9;
    cursor: pointer;
}
.list_info {
    padding: 1rem;
}
.actions {
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid #d9d9d9;
    padding: 1rem 0;
}
.error {
    color: #d32f2f !important;
}
.autocomplete {
    .input {
      padding-right: 30px !important;
    }
    .adornment {
      position: absolute;
      right: 6px;
  }
}
