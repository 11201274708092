.cpvf-camera-icon {
  .wrap {
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
  }

  .circle {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    border: 3px solid #fff;
    background: #c73ee0;
  }

  .icon {
    width: 60px;
    height: 60px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -30px 0 0 -30px;
  }

  .edit {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    border: 3px solid #fff;
    background: #888888;
  }
}