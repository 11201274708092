.wrapper {
    width: 60%;
    display: flex;
    justify-content: space-between;
}

.list {
    width: 45%;
}

.item {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 0.5rem;
    padding: 8px 0;
}

.form-control {
    width: 100%;
}

.input {
    width: 100%;
}

.arrayItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
