.map-popup {
    &_header {
        display: flex;
        border-bottom: silver solid 1px;
        justify-content: space-between;
        align-items: center;
        background-color: #daa655;
        // padding: 12px 40px 12px 20px;
        padding: 0.5rem 40px 0.5rem 1rem;
        min-height: 1rem;
        max-height: 90px;

        div {
            align-items: center;
            color: white;
            font-weight: bold;
            display: flex;
            // line-height: 1;
             width: 100%;

            span {
                margin-left: 1rem;
                color: white;
                font-weight: bold;
                line-height: 1;
                font-size: 1rem;
                //white-space: nowrap;
                //overflow: hidden;
                //text-overflow: ellipsis;
            }
        }
        .img,
        img {
            width: 20px;
            min-width: 20px;
            height: auto;
        }
        .action {
            padding: 0 !important;
            color: #fff;
            font-size: .85rem;
        }
    }

    &_body {
        padding: 0 0 10px !important;
        display: flex;
        flex-direction: column;
        color: #3d545b;
        position: relative;
        height: 100%;

        .scroll {
            height: 200px;
            overflow-y: auto;
        }

        .info__item {
            //min-height: 40px;
            display: flex;
            padding-left: 15px;
            padding-right: 15px;
            //align-items: center;
            align-items: baseline;
            font-size: 13px;
            //selecting title span
            span:nth-child(1) {
                min-width: 165px;
                padding-right: 10px !important;
                width: 40% !important;
                font-weight: 700;
            }
            &-title {
                min-width: 165px;
                padding-right: 10px !important;
                width: 40% !important;
                font-weight: 700;
            }
            // selecting content span
            span:nth-child(2) {
                padding: 10px 5px;
                //padding: 5px;
                width: 60%;
            }

            &-content {
                width: 60%;
            }

            &:nth-child(2n) {
                background-color: #f5f7f8;
            }
        }

        //.information {
        //    &.scroll {
        //        max-height: 170px;
        //        overflow: auto;
        //    }
        //}
    }

    .events {
        .event {
            min-height: 40px;
            display: -ms-flexbox;
            display: flex;
            padding-left: 15px !important;
            padding-right: 15px !important;
            -ms-flex-align: center;
            align-items: center;
            font-size: 13px;

            &:nth-child(2n) {
                background-color: #f5f7f8;
            }

            /*&.info {
                color: #00ae5c;
            }

            &.alert {
                color: #daa655;
            }

            &.danger,
            &.High {
                color: #ad1818;
            }*/

            &-title {
                width: 50%;
            }
            &-value {
                padding-left: 10px;
                width: 50%;
            }
        }
    }

    & .not-found {
        padding: .5rem 1rem;
        font-size: 0.85rem
    }
}
