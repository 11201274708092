@import './media-mixin';

.filter__btn__column {
  padding-top: 10px;
}

.filter__wrap__one__field {
  grid-template-columns: repeat(auto-fit, minmax(80%, 1fr) 110px);

  @include _768 {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }
}

.filter__wrap__more__field {
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.filter {
  display: flex;
  flex-direction: column;
  padding: 0 0 1rem 0;

  &__wrap {
    display: grid;
    align-items: flex-start;
    grid-row-gap: 0.5rem;
    grid-column-gap: 0.6rem;
    margin-top: 0.5rem;

    &__btn {
      display: flex;
      align-items: flex-start;
      &.add {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }

      & > div {
        padding: 0 !important;

        @include _480 {
          flex-direction: column;
        }

        & > button:not(.MuiIconButton-root) {
          width: max-content;
          margin-left: 0 !important;
          min-width: 110px;

          @include _600 {
            max-width: none;
          }

          @include _480 {
            width: 100%;
          }

          &:not(:first-child) {
            margin-left: 10px !important;

            @include _480 {
              margin-top: 10px;
              margin-left: auto !important;
            }
          }
        }
      }
    }
  }
  // .block {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: stretch;
  //   & > * {
  //       margin-bottom: 0.5rem;
  //   }
  // }
  // .row {
  //     display: flex;
  //     flex-direction: row;
  //     margin: 0.5rem 0;
  //     justify-content: flex-start;
  //     align-items: flex-start;
  //     @media (max-width: 600px) {
  //         flex-direction: column;
  //     }
  //     .block {
  //         margin: 0;
  //     }
  //     &__item {
  //         flex: 1;
  //         position: relative;
  //         @media (max-width: 600px) {
  //             width: 100%;
  //             &:first-child {
  //             margin-bottom: 0.5rem;
  //         }
  //         }
  //         &:not(:last-child) {
  //             margin-right: 1rem;
  //             @media (max-width: 600px) {
  //                 width: 100%;
  //                 &:first-child {
  //                     margin-top: 0.5rem;
  //                 }
  //             }
  //         }
  //     }
  //     &.align-items-center {
  //         align-items: center;
  //     }
  // }
  .form__button { // кнопки внутри формы - например "добавить еще один адрес"
      align-self: flex-start;
  }
}
