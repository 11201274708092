.tp-marker {

    .circle {
        border-radius: 50%;
        background-color: #f00;
        width: 100%;
        height: 100%;
        border: 2px solid #fff;
    }

    .image {
        position: relative;
        z-index: 80;

        .bg {
            transition: all .8s;
        }

        .icon {
            position: absolute;
            top: 12px;
            left: 10px;
            text-align: center;
            width: 20px;
            height: 20px;
            overflow: hidden;

            img {
                max-width: 100% !important;
                max-height: 100% !important;
                width: auto;
                height: auto;
                display: block;
                padding: 0;
                margin: 0;
            }
        }
    }

    .text {
        position: absolute;
        top: 12px;
        bottom: 7px;

        right: auto;
        left: 21px;
        padding-left: 15px;
        padding-right: 5px;

        background: #fff;
        text-wrap: nowrap;
        box-shadow: 0 0 3px rgba(0,0,0, .4);
        z-index: 70;
        border-radius: 5px;
        display: flex;
        align-items: center;

        font-size: .8rem;
        font-weight: 500;
        //line-height: 40px;
        color: #02a800;
        transition: all .4s;

        &.to-left {
            right: 21px;
            left: auto;
            padding-left: 5px;
            padding-right: 15px;
        }

        .delta {
            margin-left: 2px;
            color: #f00;
        }
    }
}

//.tooltip:first-letter {
//    text-transform: capitalize;
//}
.delta-tooltip {
    color: #f00;
}
