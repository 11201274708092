.form {
    padding: 20px 0;
}
.modal__form{
    padding: 20px;
    height: 100%;
}
.modal__form, .form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    // .block {
    //     display: flex;
    //     flex-direction: column;
    //     align-items: stretch;
    //     margin: 0.5rem 0;
    //     &:first-child {
    //         margin-top: 0;
    //     }
    //     &:last-child {
    //         margin-bottom: 0;
    //     }
    //     & > * {
    //         margin-bottom: 0.5rem;
    //     }
    // }
    // .row {
    //     display: flex;
    //     flex-direction: row;
    //     margin: 0.5rem 0;
    //     justify-content: flex-start;
    //     align-items: flex-start;
    //     @media (max-width: 600px) {
    //         flex-direction: column;
    //     }
    //     &.noMargin {
    //         margin: 0;
    //     }
    //     .block {
    //         margin: 0;
    //     }
    //     &__item {
    //         flex: 1;
    //         position: relative;
    //         @media (max-width: 600px) {
    //             width: 100%;
    //             &:first-child {
    //             margin-bottom: 0.5rem;
    //         }
    //         }
    //         &:not(:last-child) {
    //             margin-right: 1rem;
    //             @media (max-width: 600px) {
    //                 width: 100%;
    //                 &:first-child {
    //                     margin-top: 0.5rem;
    //                 }
    //             }
    //         }
    //     }
    //     &.align-items-center {
    //         align-items: center;
    //     }
    // }
    .form__button { // кнопки внутри формы - например "добавить еще один адрес"
        align-self: flex-start;
    }
}

.button {
    display: flex;
    align-self: flex-start;
}
