.tl-{
  &marker {
    .marker-circle {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: #34976B;
      opacity: 0.25;
      border-radius: 50%;
    }
  }

  &circleProgress {
    position: absolute;

    circle {
      fill: transparent;
      stroke-width: 4;
      stroke: #34976B;
      transform-origin: center;
      transform: rotate(-90deg);
      transition: stroke-dashoffset .4s linear;
      opacity: .5;

      &.no-transition {
        transition: none !important;
      }
    }
  }
  &hint {
    text-align: center;
    padding: 2px 5px;
  }
  &zebra {
    stroke: #585858;
    stroke-width: 10px;
    stroke-dasharray: 3px;
    stroke-linecap: butt;
  }
}
