.transport-special {

  .disabled {
    color: #c1c1c1;
    &.active {
      color: #919191;
    }
  }

  .category1 {
    background-color: #4b9c4b !important;
  }

  .category2 {
    background-color: #0fa3b0 !important;
  }

  .category3 {
    background-color: #da573e !important;
  }

  .category4 {
    background-color: #0979e2 !important;
  }
}

.transport-pass-block {
  display: flex;
  min-width: 75px;

  .icon {
    background-color: rgba(0, 0, 255, 0.54);
    width: 22px;
    height: 22px;
    border-radius: 50%;
    padding: 2px;
  }

  .name {
    font-weight: 600;
    margin-left: 10px;
    line-height: 1.7;
  }
}

.route-from {
  position: relative;
  padding-left: 20px;
  margin-bottom: 8px;

  &__icon {
    &:before {
      content: " ";
      position: absolute;
      left: 0;
      width: 9px;
      height: 9px;
      border: 2px solid rgba(0,0,0,.2);
      top: 5px;
      border-radius: 50%;
    }
    &:after {
      position: absolute;
      content: " ";
      top: 14px;
      left: 4px;
      height: 100%;
      width: 2px;
      background: rgba(0,0,0,.2);
    }
  }
}

.route-to {
  position: relative;
  padding-left: 20px;
  //margin-bottom: 5px;

  &__icon {
    &:before {
      content: " ";
      position: absolute;
      left: 0;
      width: 9px;
      height: 9px;
      border: 2px solid rgba(0,0,0,.2);
      top: 5px;
      border-radius: 50%;
    }
    //&:after {
    //  position: absolute;
    //  content: " ";
    //  top: 14px;
    //  left: 5px;
    //  height: 100%;
    //  width: 1px;
    //  background: #000;
    //}
  }
}

.routes-items {
  border-top: 1px solid rgba(0,0,0,.2);

  &__item {
    border-bottom: 1px solid rgba(0,0,0,.2);
    padding: 10px 20px;
    cursor: pointer;

    &.active,
    &:hover {
      background: #f4e4cd;
    }
  }
}

.locked-wrap {
  transition: all .4s linear;
  position: absolute;
  right: 15px;
  top: 10px;
  opacity: 0;

  &.show {
    opacity: 1;
  }
}
.layers-sidebar__layer__item:hover {
  .locked-wrap {
    opacity: 1;
  }
}