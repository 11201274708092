.picture {
    padding: 3px 3px 0;
    cursor: pointer;
    img {
        margin-right: 0 !important;
    }
}

.noCamerasMessage {
    text-align: center;
    font-weight: 500;
}

.mapPointsList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 1rem 0;

    .title {
        margin-right: 10px;
    }

    .subTitle {
        font-weight: 400;
        margin-left: 5px;
    }
}

.mapPointsModalContent {
    min-width: 400px;
    min-height: 400px;
}

.detectionImg {
    max-width: 100%;
    overflow: hidden;
    > img {
        height: 100%;
        max-height: 400px;
        width: 100%;
    }
}
