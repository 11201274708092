.block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: fit-content;
}

.container {
    padding-bottom: 20px;
}

.text {
    margin-left: 10px;
    color: #319afe;
    font-weight: bold;
    font-size: 28px;
}