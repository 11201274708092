.container {
  padding: 20px;
  .img_wrapper {
    display: flex;
    justify-content: center;
    img {
      width: 60px;
      height: 60px;
      margin: 0;
    }
  }
  .file_description {
    margin-top: 5px;
    text-align: center;
    line-height: 1.25;
    .file_name {
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .date {
      color: dimgray;
    }
  }
}

.tooltip_list {
  list-style-type: none;
  font-size: 13px;
  .tooltip_header {
    font-size: 1rem;
  }
}



