@import './fonts.scss';
@import './map/index';
@import './general-styles.scss';
@import './filters.scss';
@import './forms.scss';
@import './wanted-message.scss';
@import '../fontawesome/css/all.css';
@import './table.scss';
@import './showcase/grid.scss';

:root {
    --base-font-size: 14px;
    font-size: var(--base-font-size);
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    outline: none;
    border-radius: 5px !important;
}

.containerHeightFull {
    height: 100%;
    display: flex;
    flex-direction: column;
}
    @import './table';
