.block {
  width: 100%;
  padding: 1rem;

    &.noPadding {
        padding: 0;
    }
}

.item {
  flex: 1 0 160px;
  min-width: 160px;
}
