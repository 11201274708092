.transport-icon {
    width: 30px;
    height: 30px;
    border: 2px solid rgba(0,0,0,.2);
    // background-color: #797979 !important;
    border-radius: 50%;
    display:flex;
    padding: 2px 0;
    justify-content: center;

    &.icon1 {
      background-color: #008000 !important;
      //background-color: #4b9c4b !important;
    }

    &.icon2 {
      background-color: #0000ff !important;
      //background-color: #0fa3b0 !important;
    }

    &.icon3 {
      background-color: #ff0000 !important;
      //background-color: #da573e !important;
    }

    &.icon4 {
      background-color: #ffa500 !important;
      //background-color: #0979e2 !important;
    }
}
img.image-inner {
  display: block;
  width: 100% !important;
  height: 100% !important;
  object-fit: contain;
  margin: auto;
  &.margin {
    padding: 5px;
  }
}

.locked {
  transition: all .4s linear;
  // position: absolute;
  margin-right: -10px;
  // top: 10px;
  opacity: 0;

  &.show {
    opacity: 1;
  }
}
.layers-sidebar__layer__item:hover {
  .locked {
    opacity: 1;
  }
}
.transport-block {
  display: flex;
  align-items: center;
  padding-left: 1rem;
  // min-width: 75px;

  .icon {
    background-color: transparent;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    border: 1px solid #777;
    padding: 2px;
  }
  .content {
    margin: 0 10px;
    flex: 1 1 0;
  }
}