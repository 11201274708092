.item_delete {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-bottom: 10px;
}

.list_params {
  padding: 0 !important;
}
