.wanted {
  &__title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  &__flex {
    display: flex;
    flex-direction: row;
    p {
      margin-right: 1rem;
    }
  }
  &__pagination {
    padding: 20px 0;
  }
}
