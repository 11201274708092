h1 {
  font-size: 1.4rem;
  padding: 0 0 0.5rem 0;
}

h2 {
  font-size: 1rem;
  padding: 0 0 0.5rem;
}

h3 {
  font-size: 0.9rem;
  padding: 0.3rem 0;
}

h4 {
  padding: 0.8rem 0;
  font-size: 0.9rem;
}
.page_header {
  padding: 0 0 1rem 0;
}
.page_filter {
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.error {
  color: #d32f2f;
  display: block;
}
.title {
  font-size: 1rem;
  font-weight: 600;
}

.subtitle {
  font-weight: 600;
  font-size: 0.9rem;
}
.subtitle__info {
  font-size: 0.9rem;
}

.list {
  .list_item {
    cursor: pointer;
    &.not_active {
      cursor: default;
    }
    // font-weight: 600;
    &:not(:first-child) {
      border-top: 1px solid #d9d9d9;
    }
    &:last-child {
      border-bottom: 1px solid #d9d9d9;
    }
  }
  .info {
    padding: 0.6rem 2rem;
    margin-bottom: 1rem;
    font-weight: 400;
    width: 100%;
    background-color: #f5f5f5;
    // &:last-child {
    //   border-bottom: 1px solid #d9d9d9;
    // }
  }
}

.table_container{
  margin: 8px 0 8px 0;
  border: 1px solid rgba(224, 224, 224, 1);
}
.tab__container { // стили для табов КФВФ, Маршруты, Заявки д.р. и т.п.
  margin: 1.5rem;
}

.link {
  cursor: pointer;
}
.block {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0.5rem 0;
  &.noMargin {
    margin: 0;
  }
  &.reverse {
    flex-direction: column-reverse;
  }
  & > * {
    margin-bottom: 0.5rem;
  }
  &:first-child {
      margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}
.row {
  display: flex;
  flex-direction: row;
  margin: 0.5rem 0;
  justify-content: flex-start;
  align-items: flex-start;
  &.reverse {
    flex-direction: row-reverse;
  }
  @media (max-width: 600px) {
      flex-direction: column;
  }
  &.noMargin {
      margin: 0;
  }
  &__item {
      flex: 1;
      position: relative;
      @media (max-width: 600px) {
          width: 100%;
          &:first-child {
          margin-bottom: 0.5rem;
      }
      }
  }
  & > *:not(:last-child) {
      margin-right: 0.6rem;
      @media (max-width: 600px) {
          width: 100%;
          &:first-child {
              margin-top: 0.5rem;
          }
      }
  }
  &.align-items-center {
      align-items: center;
  }
  &.justify-content-between {
      justify-content: space-between;
  }
  &.justify-content-center {
      justify-content: center;
  }
  &.justify-content-end {
    justify-content: flex-end;
  }
}
.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.margin-right {
  margin-right: 10px;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
