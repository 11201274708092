.rules-square-marker {
    background: #e0e0e0;
    border-radius: 50%;
    border: 2px solid #585858;
}

.rs-wrapper {
    .buttons-wrapper {
        display: block;
        left: 40px;
        background: #fff;
        border-radius: 5px;
        padding: 0 .7em;
    }

    .no-selected {
        color: rgba(0,0,0,.6) !important;
    }
}
.rs-tooltip {
    padding: 0 .5rem;

    .rs-tooltip-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .text{}
        .buttons {}
    }
}
