.buttons-wrap {
  display: -webkit-flexbox;
  display: flex;
  justify-content: flex-end;
  padding: .5rem 1rem;
  align-items: center;
  .button {
    margin-left: 1rem;
    // padding: 8px 16px;
  }
  .no_margin_left {
    margin-left: 0;
  }
  &.positionLeft {
    justify-content: flex-start;
    padding: .5rem 0;
    & > .button:first-child {
      margin-left: 0;
    }
  }
  &.nopadding {
    padding: 0;
  }

  &.space-between {
    justify-content: space-between;
  }

}
.before {
  padding: .5rem 1rem;
}

.after {
  margin-left: 10px;
}
