.va_graph{
  position: relative;

  .graph_item{
    padding-top: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid #ccc;
    .table{
      margin-bottom: 10px;
      .tableBorder {
        border-right: 1px solid #e0e0e0;
      }
    }
    .title{
      text-align: center;
    }
  }
}
