.transport-special-marker-icon {

  .circle {
    border-radius: 50%;
    background-color: #D7DBDD;
    //background-color: #0fa3b0;
    width: 30px;
    height: 30px;
  }

  &.active .circle {
    background-color: #4b9c4b !important;
  }

  .icon {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;

    svg {
      width: 20px;
    }
  }

  .direction {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;

    &:after {
      position: absolute;
      content: '';
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 7px solid black;
      margin: -5px 0 0 8px;
      transform-origin: center;
    }
  }
}