@import 'src/css/media-mixin';

.preview {
  display: flex;
  width: 100%;
  & > span {
    width: 100%;
    display: flex;
  }
  @include _1024 {
    flex-direction: column;
  }
  &__wrap {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

.btn {
  margin-right: 20px;
}

.frameWrap {
  position: relative;
  text-align: center;
  & > div {
    position: absolute;
    top: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}

.frameTitle {
  padding: 0 0.5rem;
  line-height: 2rem;
  text-align: center;
  align-content: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
