$opacityGray: rgba(0, 0, 0, 0.12);

.sidebar {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    box-sizing: border-box;

    &__container {
        flex: 6;
        position: relative;
        // transition: all .4s linear;

        &.slide {
            transform: translateX(-100%);
        }

        &__menu,
        &__list {
            position: absolute;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            // overflow-y: auto;
            overflow-x: hidden;
            scrollbar-width: none;
        }

        &__list {
            left: 100%;
            display: flex;
            flex-direction: column;
        }

        &__closed {
            width: 60px;
        }

        &__opened {
            width: calc(100vw / 5);
        }
    }


    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $opacityGray;
        padding: 16px;
        &:nth-child(3n) {
            background: $opacityGray;
        }
        &__activeLayer {
            justify-content: flex-start;
            padding: 4px 8px;
        }
        &__time {
            padding: 6px 16px;
        }
    }
}

.sidebar__container__menu::-webkit-scrollbar {
    width: 0;
}

// @media screen and (max-width: 1280px) {
//     .sidebar {
//         width: 240px;
//         flex: 1;
//     }
// }
