.congestions-cluster {
    background: radial-gradient(
            50% 50% at 50% 50%,
            #edab00 27.6%,
            rgba(237, 171, 0, 0.813) 56%,
            rgba(237, 171, 0, 0) 100%
    );
    min-width: 30px;
    min-height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: 700;
    color: #fff;
    &.warning {
        background: radial-gradient(
                50% 50% at 50% 50%,
                #e06c00 25.52%,
                rgba(224, 108, 0, 0.7343) 52%,
                rgba(224, 108, 0, 0) 100%
        );
    }
    &.danger {
        background: radial-gradient(
                50% 50% at 50% 50%,
                #c02b16 18.75%,
                rgba(192, 43, 22, 0.782) 40%,
                rgba(192, 43, 22, 0) 100%
        );
    }
}
