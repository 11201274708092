
 .card {
    background-color: #fbfaf2;
    height: 100%;
    .cardContent {
        padding-right: 24px;
        &:last-child {
            padding-bottom: 16px;
        }
    }
    &:hover {
        box-shadow: 0px 5px 10px 2px rgb(0 0 0 / 10%), 0px 5px 10px 5px rgb(0 0 0 / 10%), 0px 1px 10px 0px rgb(0 0 0 / 6%);
        transform: translate(0px, -5px);
    }
    .header {
        background-color:  #3bbeff;
        font-size: 1rem;
        font-weight: bold;
        color: #fff;
        padding: 10px;
        line-height: 1.1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &.pointer {
            cursor: pointer;
        }
        .icon {
            font-size: 18px;
            margin-right: 10px;
            width: 22px;
            text-align: center;
            min-width: 22px;
        }
        div{
            display: flex;
            align-items: inherit;
        }
    }
}

.headerWrap {
    display: flex;
    justify-content: space-between;
    // margin-bottom: 1rem;
    h1 {
        font-size: 1.15rem;
        padding: 0 0 0.3rem 0;
        color: #3f51b5;
    }
}

.settingsIcon:hover {
    background-color: transparent;
}

.settingsIcon {
    font-size: 1.3rem;
    padding-right: 1.3rem;
}
