.simple-legends {
  .ltitle {
    font-weight: 700;
    margin-bottom: .5rem;
  }
  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: .4rem;

    .image {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-right: .5rem;
    }

    .name {
      font-size: 1rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
.popAbs {
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  z-index: 99999;
}

.tooltipBorderRed {
  border: 4px solid #FF0000 !important;
  padding: 0 2px !important;
}
.tooltipBorderRed::before {
  //border-right-color: #FF0000 !important;
  //border-left-color: #FF0000 !important;
  border-top-color: #FF0000 !important;
}

.tooltipBorderGreen {
  border: 4px solid #02A600 !important;
  padding: 0 2px !important;
}
.tooltipBorderGreen::before {
  border-right-color: #02A600 !important;
  border-left-color: #02A600 !important;
}
