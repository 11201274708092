.dtp_graph{
  position: relative;
  .get_report{
    display: flex;
    align-items: flex-start;

    .formatBtn{
      margin-left: 15px;
      height: 100%;
    }
  }

  .fieldFormat{
    max-width: 100%;
    width: 200px;
  }
}

.action_panel {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
