#map,
.map-container {
    width: 100%;
    height: 100%;
    z-index: 0;

    /*.marker {
        background-color: #fff;
        border-radius: 50%;
    }*/
    &.small {
        .leaflet-control-zoom {
            right: 0 !important;
        }
    }
    svg * {
        outline: none;
    }
    // фикс для выбранного слоя карты (tile)
    .leaflet-control-layers-list .checked {
        color: #006195;
        background: #e7e7e7;
    }
}
.leaflet-control {
    &-layers {
        border: none !important;
        border-radius: 0;
        box-shadow: 3px 5px 5px rgba(122, 122, 122, 0.5) !important;

        &-toggle {
            background-image: url('../../helpers/mapHelper/img/layers.svg') !important;
            background-size: contain;
            border-radius: 0px !important;
            height: 34px !important;
        }

        &-list {
            font-size: 1rem;

            label {
                margin-bottom: 0.5rem;
                &:hover {
                    background-color: rgba(0, 97, 149, 0.25);
                }
            }

            input {
                display: none;

                &:checked + span > span {
                    color: #006195;
                    //background: #e7e7e7;
                }
            }
        }
    }

    &-zoom {
        position: absolute;
        margin: 0;
        //right: 55px;
        //height: 34px;
        //width: 70px;
        right: 0;
        top: 50px;
        width: 34px;
        height: 70px;
        border: 0 !important;
        //display: flex;
        transition: 0.2s;
        box-shadow: 3px 5px 5px rgba(122, 122, 122, 0.5) !important;

        & a {
            border: none;
            //width: 35px !important;
            //height: 34px !important;
            width: 34px !important;
            height: 35px !important;
            //display: inline-block;
            border-right: 1px solid #ebeeee;
        }

        &-in {
            &:after {
                content: '';
                position: absolute;
                left: 0;
                //width: 50%;
                //height: 100%;
                height: 50%;
                width: 100%;
                background: url('../../helpers/mapHelper/img/plus.svg') no-repeat white;
                background-position: center;
            }
            &:hover:after {
                background-color: #f4f4f4;
            }
        }

        &-out {
            &:after {
                content: '';
                position: absolute;
                //right: 0;
                //width: 50%;
                //height: 100%;
                left: 0;
                height: 50%;
                width: 100%;
                background: url('../../helpers/mapHelper/img/minus.svg') no-repeat white;
                background-position: center;
            }
            &:hover:after {
                background-color: #f4f4f4;
            }
        }
    }
}

.layer-control__item {
    display: flex;
    position: relative;
    vertical-align: top;
    line-height: 2.5;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 10px;
    cursor: pointer;

    &:hover {
        //color: #006195;
        //background: #e7e7e7;
        background: #fff;
    }

    img {
        margin: 4px 8px;
    }
}

.custom-popup-wrapper {
    .leaflet-popup-content-wrapper {
        padding: 0;
        border-radius: 2px;
    }

    .leaflet-popup-content {
        margin: 0;
    }

    .leaflet-popup-close-button {
        font-size: 23px !important;
        color: #fdfbf7 !important;
        font-weight: normal !important;
        padding: 0 !important;
        top: 0.15rem !important;
        right: 10px !important;
    }
}
