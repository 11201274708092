.list_style {
  padding: 0;
  //.row {
  //  align-items: center;
  //}

  .title_item {
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.87);
    line-height: 1;
    padding: 0.8rem 0;
  }

  .list_item {
    border-top: 0 !important;
    &:nth-of-type(odd) {
      background-color: rgba(63, 81, 181, 0.08);
    }
  }

  .content_item {
    font-size: 1rem;
    overflow-wrap: break-word;
    margin-right: 0;

    &.align-items-center {
      align-items: center;
    }

    .margin-right {
      margin-right: 1rem;
    }

    &:last-child .margin-right {
      margin-right: 0;
    }
  }
}