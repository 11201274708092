.mapPopUpListener-flex {
    display: flex;
    justify-content: space-between;

    .spanIcon {
        margin-right: 1rem;
        display: flex;
        align-items: center;

        img {
            width: 24px;
            height: auto;
            max-height: 24px;
            margin-right: 0;
        }
        svg {
            width: 20px;
        }

        .inner-icon {
            display: flex;
            align-items: center;
            width: 20px;
        }
    }
}
