.data-table {
    display: flex;
    flex-flow: column nowrap;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;

    &-row {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding: 6px 16px;
        gap: 16px;

        &-cell {
            display: flex;
            flex: 1;
            justify-content: flex-start;
            align-items: center;
            position: relative;
            &.actions {
                display: flex;
                justify-content: flex-end;
                max-width: 120px;
                position: relative;
            }
        }

        .draggable-actions:not(last-child) {
            display: flex;
            justify-content: flex-end;
            max-width: 120px;
        }

        &:nth-child(even):not(.heading) {
            background-color: #3f51b514;
        }

        & > :only-child {
            justify-content: flex-end !important;
        }
    }

    .heading {
        font-weight: bold;
        padding: 12px 16px;
        position: relative;

        .data-table-row-cell:not(:last-child)::after {
            content: '';
            width: 1px;
            height: 20px;
            border-right: 1px solid rgba(0, 0, 0, 0.1);
            position: absolute;
            right: -4px;
            top: 50%;
            transform: translateY(-50%);
        }

        .data-table-row-cell {
            padding-right: 0;
            display: flex;
            justify-content: space-between;
            &.actions {
                display: flex;
                justify-content: flex-end;
                max-width: 120px;
                position: relative;
            }

            &.actions:not(:last-child) {
                justify-content: space-between;
            }
        }

        .dragging::after {
            content: none !important;
        }

        .menu {
            position: absolute;
            right: 16px;
        }
    }
}

.dragging {
    background-color: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
    border-radius: 5px;
    padding: 8px 16px;
    margin-right: 16px;
    z-index: 1;
}
