.layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: calc(100vh - 64px - 32px);
  justify-content: space-between;
}
.content {
  background-color: #fff;
  padding:  0 1rem;
  margin: 0 0 0.5rem 0;
  flex: 1;
  overflow-y: scroll;
  min-height: 200px;
}
.actionPanel {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 0.3rem 1rem;
  margin:  0.3rem 0;
  font-size: 0.8rem;
  border-bottom: 1px solid #e4edef;
}

.header {
  padding: 0 0 0.2rem 0;
  font-size: 1.15rem;
  color: rgb(63, 81, 181);
}

.header_panel {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 0.4rem;
}

