.table-container {
  margin: 10px;
  padding: 10px;
  max-width: 100%;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.styled-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 10px;
  text-align: left;
}

.styled-table th, .styled-table td {
  padding: 12px 15px;
}

.styled-table thead tr {
  /* background-color: #009879; */
  /* color: #ffffff; */
  text-align: left;
  font-weight: bold;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

/* .styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
} */

.styled-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}
