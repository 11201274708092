/* Основной стиль контейнера управления */
.custom-control-bar {
    display: flex;
    flex-direction: column; /* Разделить элементы на строки */
    // align-items: center;
    height: 70px !important;
    padding: 8px !important;
    // padding-top: 12px !important;
}

.vjs-progress-container {
    width: 95% !important;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 20px;
    padding-left: 8px;
}

.vjs-time-control {
    line-height: 2em;
}

.vjs-progress-control {
    width: 100%;
}

/* Контейнер для остальных кнопок */
.vjs-controls-row {
    display: flex;
    // flex-wrap: wrap; /* Разбить кнопки на несколько строк */
    justify-content: space-between; /* Центрировать кнопки */
    width: 100%;
    align-items: center;
    height: 30px;
}

.control-row-child {
    display: flex;
    flex-wrap: wrap; /* Разбить кнопки на несколько строк */
    justify-content: center; /* Центрировать кнопки */
    height: 30px;
}
  
/* Стили для кнопок */
.vjs-controls-row .vjs-control {
    flex: 1 0 auto;
    margin: 5px; /* Отступы между кнопками */
}

.vjs-controls-row .vjs-play-control,
.vjs-controls-row .vjs-volume-panel,
.vjs-controls-row .vjs-fullscreen-control,
.vjs-controls-row .vjs-mute-control,
.vjs-controls-row .vjs-remaining-time,
.vjs-controls-row .vjs-duration,
.vjs-controls-row .vjs-playback-rate {
    margin-bottom: 5px; /* Отступ снизу для кнопок */
}

.modal_style {
    min-width: 650px;
    overflow-x: hidden !important;
}

select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: none;
    border: none;
    color: #fff;
}
