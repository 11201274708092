.camera_settings{
  margin-bottom: 20px;

  .title{
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  .cameraOnlineTimeBorder{
    color: #daa655;
  }

  .cameraOfflineTimeBorder{
    color: #ad1818;
  }

  .form_block{
    display: flex;
    align-items: center;

    .form{
      margin-right: 20px;
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }

    .btn{
      margin-right: 20px;
    }

  }
}
