@import './src/css/media-mixin';

.container {
  margin: 1.5rem;
}

.routes {
  margin: auto;

  &__item {
    border-bottom: 1px solid #d9d9d9;
  }
}

.title {
  cursor: pointer;

  &__item {
    margin-right: 10px;
    font-size: 1rem;
  }

  &__item_bold {
    font-size: 1rem;
    font-weight: 600;
    margin-right: 5px;
  }
}

.info_block{
  padding: 0 16px 16px 16px;
  &__item{
    display: flex;
    flex-wrap: wrap;
    span {
      margin-right: 5px;
    }
  }
}

.tariff {
  display: flex;
  &__item {
    display: flex;
    flex-direction: column;
    margin-right: 40px;
    &_title {
      margin-bottom: 7px
    }
  }
}

.route_passport {
  display: flex;
  height: 100%;

  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    height: 99%;
    margin: auto;

    &_item {
      width: 49%;
      height: 100%;
      overflow-y: auto;

      > ul {
        > li {
          width: 50%;
        }
      }
    }
  }
}

.route_passport_title{
  font-size: 25px;
  font-weight: 500;
  color: #224591;
}

.mapCircleIcon {
  width: 15px !important;
  height: 15px !important;
  background: #ffffff;
  border-radius: 50%;
  border: 1px solid black;
  right: 0;
}

.mapMarkerIcon {
  font-size: 35px;
  color: #2c83ca;
}

.stationName {
  position: relative;
  p {
    position: absolute;
    top: -10px;
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
    margin: 0 15px;
    text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
  }
}

.textFiledWithoutArrows {
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none; // Yeah, yeah everybody write about it
  }
  input[type='number'],
  input[type="number"]:hover,
  input[type="number"]:focus {
    appearance: none;
    -moz-appearance: textfield;
  }
}

.helperText {
  margin: 0;
  padding-left: 14px;
}
