.wrapper {
    background-color: #eef5fd;
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: column;
}

.search {
    display: flex;
    align-items: center;
    // margin-bottom: 1rem;

    input {
        display: block;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        outline: none;
        border-radius: 5px !important;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
}

.item {
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;

    button {
        display: flex;
        align-items: center;
        font-weight: 700;
        border-radius: 5px !important;
        background: none;
        border: none;
        cursor: pointer;
        padding: 0;
        text-align: left;
    }

    &__title {
        text-align: left;
        padding-left: 15px;
    }
}

.popper {
    max-width: 500px;
    display: block;
    margin-left: 25px;
    background: rgba(220, 220, 220, 1.000);
    padding: 10px 15px;
    border-radius: 10px;
    &__button {
        margin-left: 15px!important;
    }
}

.wrap {
    padding: 0.5rem 0;
    display: flex;
    align-items: center;
}

.loading {
    margin-bottom: .5rem;
}
