.contextmenu {
  .button {
    cursor: pointer;
    display: block;
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
    font-weight: 400;
    text-align: center;
    user-select: none;
    border: 1px solid transparent;
    outline: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-bottom: .5rem;

    &:last-child {
      margin-bottom: 0;
    }

    &.red {
      background-color: #dc3545;
      border-color: #dc3545;
    }
  }
}