.map-search-control {
  position: relative;
  //z-index: 999;

  label {
    background: #fff;

    &[class*="shrink"] {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .textBlock {
    background-color: #fff;

    &.active {
      width: 500px;

      input {
        width: 100%;
      }
    }

    .MuiOutlinedInput-root {
      border-radius: 0 !important;
    }
  }

  &_list {
    background: #fff;
    position: absolute;
    top: 100%;
    left:0;
    right:0;

    &_item {
      display: block;
    }
  }
}