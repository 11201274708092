.mediaModalContainer {
  margin: 1rem 1rem 0;
  display: flex;
  flex-direction: column;
  height: 88vh;
  justify-content: space-between;

  .navigationBar{
    border-bottom: 1px solid #d9d9d9;
    display: flex;
    flex-wrap: wrap;

    &__item {
      margin: 0 10px 10px 0;
      display: flex;
    }

    .errors{
      display: flex;
      align-items: center;
      min-width: 350px;
      max-width: 100%;
      margin-bottom: 10px;

      .field{
        width: 80%;
      }

      .btn{
        margin-left: 10px;
        min-width: 40px;
        height: 40px;
        align-self: self-start;
      }
    }

  }

  .content{
    margin: 0;
    display: flex;
    // justify-content: space-between;
    // flex-wrap: wrap;
    height: auto;
    max-height: 65%;
    flex: 1;
    > * {
      margin-right: 1rem;
    }

    .sidebar{

      &__header{
        margin-bottom: 10px;
      }

      &__content{
        max-height: 90%;
        overflow-y: scroll;

        .mediaBlock{
          cursor: pointer;
          position: relative;
          margin-bottom: 20px;
          border: 5px solid #d9d9d9;
          display: flex;
          padding: 5px;

          .media {
            width: auto;
            height: auto;
            max-height: 100px;
            max-width: 100px;
            position: relative;
            margin: auto;
          }
        }

        .active{
          border: 5px solid #00adff;
        }
      }
    }

    .mainContentBlock {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      align-items: flex-start;
      position: relative;

      > img, video {
        max-width: 100%;
        max-height: 100%;
        width: fit-content;
        height: 95%;
        margin: 0;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      flex: 2;
    }

    .playArrow {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.5);;
      border-radius: 50%;
      padding: 10px;
      font-size: 30px;
      color: white;
      height: 50px;
      width: 50px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
  }

  .footer {
    border-top: 1px solid #d9d9d9;
    max-height: 30%;

    .info{
      display: flex;
      flex-wrap: wrap;
      max-width: 100%;

      > div {
        padding: 5px 5px 5px 0;
        width: 100%;
        max-width: 50%;

        > strong{
          margin-right: 5px;
        }
      }
    }
  }
}

.printInfo {
  @media print {
    display: block;
    width: 100%;
    position: relative;
    & > .img {
      position: relative;
      margin-bottom: 25px;
      width: 100%;
      display: block;
      & > img {
        display: block;
        width: 100%;
        height: auto;
        margin-bottom: 25px;
      }
    }
    & > .footer {
      position: relative;
      display: block;
      padding: 0 10px;
    }
  }
  display: none;
  width: 100%;

}
