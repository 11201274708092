.vaa-table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;

  tr {
    th:first-child,
    td:first-child {
      padding-left: 15px;
    }
    th:last-child,
    td:last-child {
      padding-right: 15px;
    }
    &:nth-of-type(2n + 1) {
      td {
        background: #f5f5f5;
      }
    }
    td {
      padding: 5px 7px;
    }
    th {
      background: #e2e6ea;
      padding: 9px 7px;
    }
  }
}

.vaa-refresh {
  position: absolute;
  z-index: 99;
  right: 34px;
  top: -32px;

  img {
    width: 11px;
    fill: #fff;
  }
}