.marker-cluster {
  z-index: 999 !important;
  .count {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 56%;
    height: 56%;
    top: 22%;
    left: 22%;
    background: #fff;
    color: #000;
    border-radius: 50%;
    font-size: 14px;
    line-height: 1;
  }

  .icons {
    position: absolute;
    top: 100%;
    transition: all .2s linear;
    display: flex;
    flex-wrap: wrap;
    background: #fff;
    border-radius: 5px;
    margin-top: 8px;
    padding: 6px 4px 3px;
    justify-content: center;
    left: 50%;
    opacity: 0;
    height: 0;
    z-index: 99999 !important;

    &:before {
      content: " ";
      width: 0;
      height: 0;
      border: 7px solid transparent;
      border-bottom-color: #fff;
      border-top: 0;
      position: absolute;
      top: -7px;
    }

    & > svg {
      width: 30px;
      margin: 0 2px 2px;
    }
    & > .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: #e7e7e7;
        width: 28px;
        height: 28px;
        margin: 1px 2px 2px;

        & > i {
            font-size: 1rem;
            color: rgba(0, 0, 0, 0.54);
        }
    }
  }
  &:hover .icons {
    opacity: 1 !important;
    height: auto;
  }
}

.custom-cluster-marker {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: block;
}
