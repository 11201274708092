.paper {
    position: relative;
    padding: 16px;
    border: 1px solid rgba(0, 0, 0, 0.87);
}

.label {
    position: absolute;
    top: -10px;
    left: 20px;
    background-color: inherit;
    padding: 0 4px;
    font-size: 0.85rem;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.87);
}
