$circle-color: #8f999d;

.route_popup {
    padding: 1rem;
    //max-height: 300px;
    //overflow-y: auto;

    .info__title {
        margin: 0 0 1rem;
        font-size: 1rem;
    }
    .info__carriers {
        margin-bottom: 1rem;
        font-size: 1rem;
    }
    .routes_wrapper {
        background-color: #eef1f2;
        padding: 10px;
        color: #46565b;
        max-height: 200px;
        overflow-y: auto;
    }
    .circle {
        width: 6px;
        height: 6px;
        margin-right: 5px;
        display: block;
        border-radius: 50%;
        border: 1px solid $circle-color;
        //background: #cccccb;
    }



    .stations {
        // padding-left: 22px;
        display: flex;
        flex-direction: column;
        // align-items: flex-start;
        // align-content: center;
        // font-size: 12px;
        // line-height: 1;
        .item {
            font-size: 15px;
            border-left: 1px solid $circle-color;
            padding-left: 20px;
        }
        // .item:before {
        //     content: '';
        //     display: block;
        //     width: 1px;
        //     position: relative;
        //     height: 50px;
        //     background: $circle-color;
        //     left: 1.5px;
        //     top: -50px;
        // }
    }

    .platforms {
        margin-top: 20px;
        &__header {
            display: flex;
            margin-bottom: 15px;
            font-weight: bold;
            font-size: 1rem;
            & > span {
                flex: 1 0;
            }
        }
        &__list {
            //max-height: 260px;
            //overflow-y: auto;
            //display: flex;
            //overflow-x: hidden;
            //&:last-child {
            //    margin-right: -16px;
            //}
        }
        .list {
            margin-left: 10px;
            padding: 0 10px 0 20px;
            flex: 1 0;
            font-size: 1rem;
        }
        .list-item {
            list-style-type: circle;
            color: #738185;
            //line-height: 2px;
            margin-bottom: 15px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
