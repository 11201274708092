

.wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    background-color: #e4edef;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    margin: 0;
    margin-bottom: .5rem;
    font-size: 0.8rem;
}

.buttonsWrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    & > *:not(:first-child) {
        margin-left: 1rem;
    }
    button:disabled {
        background-color: #a2d0fe;

    }
}

.singleElement {
    justify-content: flex-end;
}
