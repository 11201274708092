.layers-sidebar {
  .border-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  .flex {
    display: flex;
    align-items: center;
  }

  &__header {
    display: block;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  .go-to-back {
    cursor: pointer;
    padding: .5rem 1rem;
    font-weight: 700;

    i {
      margin-right: .5rem;
    }
  }

  &__layer-not-list {
    padding: .5rem 1rem;
    font-weight: 500;
  }

  &__title {
    padding: .5rem 1rem;

    //span {
    //  font-weight: 500;
    //}
    .title {
      font-size: 1.15rem;
      font-weight: 700;
      margin-right: 0.5rem;
    }
    .total {
      font-size: 12px;
      font-weight: 500;
    }
    .title_container {
      display: flex;
      align-items: center;
    }
    .subtitle {
        font-size: 1rem;
        font-weight: 400;
        color: #666;
    }
  }

  &__filter {
    padding: .5rem 1rem;
    position: relative;
  }

  &__flex-column {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
  }

  &__total-count {
    font-size: 20px;
    font-weight: 700;
    padding: .5rem 1rem;
    text-align: center;
  }

  &__layer {
    &__item {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      cursor: pointer;
      padding: 1rem;
      position: relative;

      &:nth-of-type(2n) {
        color: #7d5e15;
        background: rgba(0, 0, 0, .05);
        //background: #f5f7f8;
      }

      &.active {
        color: #7d5e15;
        background: #f4e4cd;
      }

      &:hover {
        color: #7d5e15;
        background: #f4e4cd;
      }

      &__icons {
        margin-right: 1rem;

        .icon {
          width: 22px;
          //
          //&.circle {
          //  background: rgba(0, 0, 255, 0.54);
          //  width: 22px;
          //  height: 22px;
          //  border-radius: 50%;
          //  padding: 1px;
          //}
        }
      }

      &__content {
        padding-right: 10px;

        .description {
          font-weight: 700;
        }

        .description,
        .status {
          margin-bottom: .2rem;
        }
      }
    }
  }

  &__empty {
    padding: 1rem;
  }

  &__popup {
    background: #ffffff;
    z-index: 999;
    padding: 1rem;
    box-shadow: 0 0 .5rem rgba(0,0,0,.2);
    max-width: 400px;

    .title {
      font-size: 1rem;
      margin-bottom: 1rem;
    }

    .selected {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -.25rem .5rem;

      .item {
        margin: 0 .25rem .5rem;
      }
    }
  }
}
