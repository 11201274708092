.settings{

  .section{
    margin-bottom: 15px;
    width: 500px;
    max-width: 100%;
  }

  .btn{
    margin-left: 10px;
  }
}