#metro-scheme {
	overflow: hidden;
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
    display: flex;
}

.metro-transport-incidents-edit-scheme {
	width: 100%;
	height: 100%;
    display: flex;
    flex-direction: column;

	&__image {
		cursor: all-scroll;

		.disabled {
			opacity: 0.6;
			cursor: no-drop;
			pointer-events: none;
		}
		.marker {
			&:hover {
				r: 6;
			}
			&:hover + text {
				font-size: 9px;
			}
		}
		circle {
			cursor: pointer;
			fill: #ffffff;
		}
		text.marker {
			cursor: text;
		}
		.route {
			font-weight: 600;
		}
		.selected {
			fill: #ffffff;
		}
		.selected-text {
			font-weight: 600;
		}
	}
}
