.preview {
  & > img {
    height: auto;
  }
}

.upload_image {
  display: none;
}

.upload_label {
  cursor: pointer;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  font-size: 0.875rem;
  color: #3f51b5;
  padding: 8px 16px;
}

.upload_block {
  padding: 6px 16px;
  border-radius: 4px;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}