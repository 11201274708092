.root {
    //max-width: 100%;
    //max-height: 100%;
    margin: 0;
    top: 3%;
    left: auto;
    right: auto;
    bottom: auto;
    width: 60%;
    z-index: 9999;
    padding: 0;
    overflow-x: hidden;
    inset: auto;
    &.noPadding {
        padding: 0;
        margin: 0;
    }
    .titleContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid #dcdcdcf5;
    }
    .titleButton {
        &.absolute {
            position: absolute;
            top: 0.25rem;
            right: 0.25rem;
            z-index: 10;
        }
        &.alignItemsCenter {
            display: flex;
            align-items: center;
        }

        button {
            margin: auto;
        }
    }
    .titleButtonsBlock {
        display: flex;
        margin-right: .5rem;
    }
    .titleButtonsBlockMargin {
        margin-right: 1rem;
    }
    .title {
        padding: 0.5rem 1.2rem;
        max-width: 100%;
        flex: auto;
        // flex: 0 0 100%;
        //border-bottom: 1px solid #dcdcdcf5;
    }
    .content {
        // padding-top: 8px;
        position: relative;
    }
    .noOverflowY {
        overflow-y: hidden;
    }
    .noPadding { // этот стиль применяется к контенту
        padding: 0;
    }
    &.small {
        width: 30%;
    }
    &.medium {
        max-width: 50%;
    }
    &.fullWidth {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 95%;
        height: 95%;
        max-width: 95%;
        max-height: 95%;
    }
    &.heightMedium {
        height: 65%;
        max-height: 65%;
    }
    &.heightSmall {
        height: 35%;
        max-height: 35%;
    }
}
.overflowVisible {
    overflow: visible !important
}
.actions {
    border-top: 1px solid #dcdcdcf5;
    background: #eee;
}
