.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  background-image: inherit;
  background-position: bottom right;
  padding: 0 3px 3px 0;
}

.react-grid-item > .react-resizable-handle::after {
  content: "";
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 35px;
  height: 35px;
  border-right: 4px solid rgba(255, 0, 0, 0.8);
  border-bottom: 4px solid rgba(255, 0, 0, 0.8);
}