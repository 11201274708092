.videoAnalytic{
  .filterItem{
    display: flex;
    .field{
      width: 85%;
    }
    .checkText{
      font-weight: 500;
      margin-left: 10px;
    }

    .btn{
      margin-left: 10px;
      min-width: 40px;
      height: 40px;
      align-self: self-start;
    }
  }

  .listItem{
    border-bottom: 1px solid #d9d9d9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .icons{
      display: flex;
    }
    .event{
      display: flex;
      //flex-wrap: wrap;
      &__title{
        font-weight: 700;
        margin-right: 5px;
      }
    }
    .eventItem {
      margin-right: 1rem;
      max-width: 430px;
    }
    .eventItemField {
      padding: 5px 2px 5px 0;
      width: 100%;
    }
  }

  .picture {
    padding: 3px 3px 0;
    cursor: pointer;
    img {
      margin-right: 0 !important;
    }
  }
}

.noCamerasMessage{
  text-align: center;
  font-weight: 500;
}

.mapPointsList{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // padding: 10px 20px;

  .title{
    margin-right: 10px;
  }

  .subTitle {
    font-weight: 400;
    margin-left: 5px;
  }
}

.mapPointsModalContent{
  min-width: 400px;
  min-height: 400px;
}

.mapIcon{
  background-color: inherit;
  .mapIconDiv{
    border: 3px solid white;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    position: relative;
  }
  img{
    padding: 4px;
    position: absolute;
    top: 0;
    left: -8px;
    right: 0;
    bottom: 0;
    margin: auto;
  }
}

.detectionImg{
  max-width: 100%;
  overflow: hidden;
  > img {
    height: 100%;
    max-height: 400px;
    width: 100%;
  }
}
