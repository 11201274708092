.header {
    padding: 8px;
    flex: none;
    display: flex;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    z-index: 3;
    background-color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    .left {
        display: flex;
        justify-content: space-between;
        gap: 60px;
        flex: 1 1;
        .logo_wrapper {
            flex: 0 1 auto;
            .link {
                display: flex;
                align-items: center;
                gap: 8px;
                text-decoration: none;
                color: rgba(0, 0, 0, 0.87);
            }
        }
    }

    .right {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 24px;
        flex: 4 1 54px;
        .location {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}

.menu_item_button {
    font-weight: 400;
    > span {
        min-width: 26px;
    }
}

.menu_icon {
    color: #3366cc;
}

.item {
    display: flex;
    align-items: center;
}

.expand_btn_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.avatar {
    width: 24px;
    height: 24px;
    font-size: .8rem;
}

// @media screen and (max-width: 1280px) {
//     .header {
//         padding: 8px 16px 8px 80px;
//     }
//     .project_description {
//         font-size: 0.9rem;
//     }
// }
