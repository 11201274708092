.button {
    border: 1px solid;
    min-width: 40px;
    color: inherit;
    background-color: #e0e0e0;
    padding: 6px 16px;
    border-color: #e0e0e0;
}
.left {
    border-radius: 12px 0 0 12px;
}
.right {
    border-radius: 0 12px 12px 0;
}
.selected {
    color: #fff;
    background-color: #3f51b5;
    border-color: #3f51b5;
    &:hover {
        background-color: #2c387e;
    }
}
