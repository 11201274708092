.change-password {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;

    h2 {
        margin-bottom: 0.5rem;
    }

    .form {
        width: 400px;
        border: 1px solid #000;
        padding: 1rem;
        border-radius: 9px;
        position: relative;
    }

    .buttons {
        display: flex;
        margin-top: 10px;
        & > :first-child {
            margin-right: 10px;
        }
    }

    .mt-1 {
        margin: 5px 0;
    }

    .error {
        color: #d32f2f;
    }
}
