.block {
    padding: 8px 8px;
}

.input {
    margin-right: 8px;
}

.item_row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.btns {
    margin-left: 16px;
}