$titleColor: rgba(0, 0, 0, 0.87);
$bgGreyOpacity: rgba(0, 0, 0, 0.2);
$iconColor: rgba(0, 0, 0, 0.54);
$notReading: #3f51b5;

.notify {
    &__title {
        text-align: center;
        color: $titleColor;
        font-size: 13px !important;
        font-weight: 500 !important;
        padding: 8px 0;
        background: $bgGreyOpacity;
        text-transform: uppercase;
    }
    &__notFound {
        display: block;
        width: 100%;
        padding: 10px 0;
        color: $titleColor;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
    }
    &__popover > div {
        &:nth-of-type(3) {
            height: 1000px;
        }
    }
    &__panel {
        max-height: 380px;
        overflow: auto;
        height: inherit;
    }
    &__tab {
        position: absolute !important;
        top: 24px;
        right: 45px;
        border-radius: 50%;
        & > span {
            background: $iconColor;
        }
        &__wrap {
            min-height: 50px !important;
        }
    }
    &__card {
        display: flex;
        flex-direction: column;
        //padding: 15px 20px;
        cursor: pointer;
        &:not(:first-child) {
            padding-top: 15px;
        }
        &:not(:last-child) {
            border-bottom: 1px solid $bgGreyOpacity;
        }
        &__field {
            display: flex;
            align-items: center;
            &__mark {
                display: table;
                padding: 5px;
                border-radius: 30%;
                border: 2px solid $titleColor;
                margin-right: 10px;
                background: transparent;
                transition: 0.2s;
                cursor: pointer;
                &:hover {
                    transition: 0.2s;
                    background: $bgGreyOpacity;
                }
            }
            &__date {
                font-size: 12px;
                font-weight: 500;
                color: $iconColor;
            }
        }
        &__message {
            padding-top: 5px;
            transition: 0.2s;
            &:hover {
                transition: 0.2s;
                color: $bgGreyOpacity;
            }
        }
        &__type {
            font-size: 14px;
            font-weight: 500;
            color: $iconColor;
        }
    }
    &__button {
        width: 100%;
        text-transform: uppercase;
        position: absolute;
        bottom: 0;
    }
}

.container {
    padding: 0 !important;
    height: 100%;
    position: relative;
}

.box {
    position: relative;
    height: inherit;
}

.active {
    background: $notReading;
    transition: 0.2s;
}

.tabpanel {
    height: calc(100% - 121px);
}
