.list-statistic-container {
  .item {
    display: flex;
    justify-content: space-between;
    padding-left: 26px;

    .name {
      font-weight: 700;
      align-items: center;
      display: inline-flex;
      margin-right: 1rem;
    }
    .value {
      font-weight: 700;
      align-items: center;
      display: inline-flex;
    }

    &.big {

      .name {
        font-size: 1rem;
      }
      .value {
        color: #ff0000;
        font-size: 1.2rem;

        &.big-size {
          font-size: 2rem;
        }
      }
    }

    &.small {
      margin-bottom: .8rem;

      .name {
        font-size: 0.875rem;
        font-weight: 400;
      }
      .value {
        //color: #8d0791;
        //font-size: 2rem;
        font-size: 0.875rem;
        color: #f407f7;
      }
    }

      &.no-margin-bottom-last:last-child {
          margin-bottom: 0;
      }

  }
}
