@import './src/css/media-mixin';

.dtp {
  margin: auto;

  &__item {
    border-bottom: 1px solid #d9d9d9;

    i {
      margin-right: 10px;
    }
  }

  &__pagination {
    display: flex;
    align-items: center;
  }

  small {
    display: block;
    margin-top: 0.25rem;
    color: #e00;
  }
}

.title {
  cursor: pointer;

  &__item {
    margin-right: 10px;
  }

  &__item_bold {
    font-weight: 600;
    margin-right: 5px;
  }
}

.dtp_info_block {
  display: flex;
  flex-wrap: wrap;
  padding: 16px;
  justify-content: space-between;

  &__item {
    flex: 1;
    min-width: 200px;

    p {
      margin-bottom: 5px;
    }
  }
}

.preview {
  width: 300px;
  img {
    width: 100%;
    height: auto;
  }
}

.preview_modal {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: auto;
    height: 100%;
  }
}


