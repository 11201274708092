.custom_list_style {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0;

  .item {
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.87);
    line-height: 1;
    padding-right: 1rem;
    word-wrap: break-word;

    &.no-padding {
      padding-right: 0;
    }
  }
  .header {
    @media (max-width: 900px) {
        display: none;
    }
  }

  .list_item {
    border-top: 0 !important;
    &:nth-of-type(even) {
      background-color: rgba(63, 81, 181, 0.08);
    }
    .mobile-only {
      display: none;
    }

    @media (max-width: 900px) {
      display: block;

      .mobile-only {
        display: inline-block;
        margin-right: .5rem;
      }

      .cell {
        width: 90% !important;
        max-width: 100% !important;
      }
    }
  }

  .open_item {
    display: block;

    .mobile-only {
      display: inline-block;
      margin-right: .5rem;
    }

    .cell {
      width: 90% !important;
      max-width: 100% !important;
    }
  }

  .clickable_item {
    cursor: pointer;
  }

  .info {
    padding: 0.6rem 2rem;
    margin-bottom: 1rem;
    font-weight: 400;
    width: 100%;
    background-color: #f5f5f5;
    // &:last-child {
    //   border-bottom: 1px solid #d9d9d9;
    // }
  }
}
