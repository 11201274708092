.cardStyle {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.scrollbarsContainer {
    display: flex;
    height: 100%;
}

.scrollbars {
    height: 100%;
}
