.sidebar-tp-legend {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 1.2rem .5rem .5rem;

    &.column {
      flex-direction: column;
    }

  .item {
    margin: .2rem .5rem .2rem;

    .title {
      font-size: .8rem;
      margin-bottom: .3rem;
    }

    .small {
      font-size: .7rem;
      margin-left: 0.4em;
    }

    .flex {
      display: flex;
      margin-bottom: .3rem;
      align-items: center;
    }
  }

  .image {
    display: flex;
    width: 20px;
    height: 20px;
    border-radius: 50%;

      &.circle-color {
          width: 15px;
          height: 15px;
      }

    img {
      max-width: 100%;
      max-height: 100%;
      margin: auto;
      padding: 3px;
      object-fit: contain;
    }
  }

  .title {
      display: block;
      width: 100%;

  }
}
.close-legend-icon {
  position: absolute;
  right: 0;
  top: 0;
}
