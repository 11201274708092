.card {
  background-color: #fbfaf2;
  //flex-grow: inherit;

  &.fill {
    height: 100%;
  }

  &.marginBottom:not(:last-child) {
    margin-bottom: 1rem;
  }
  &.fillOthers {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    //flex-grow: inherit;
  }
  //&.flexGrow {
  //  flex-grow: inherit;
  //}

  &.hover:hover {
    box-shadow: 0 5px 10px 2px rgb(0 0 0 / 10%), 0 5px 10px 5px rgb(0 0 0 / 10%), 0 1px 10px 0 rgb(0 0 0 / 6%);
    transform: translate(0px, -5px);
  }

  .cardHeader {
    background-color: #3bbeff;
    font-size: 1.2rem;
    font-weight: bold;
    color: #fff;
    padding: 10px;
    line-height: 1.1;
    display: flex;
    align-items: center;
    &.small {
      padding: 0.5rem;
      font-size: 1rem;
      line-height: 1;
    }
    &.onClick {
      cursor: pointer;
    }

    .icon {
      font-size: 18px;
      margin-right: 10px;
      width: 22px;
      text-align: center;
      min-width: 22px;
      &.smallIcon {
        font-size: 1rem;
        margin-right: 0.5rem;
      }
    }
  }

  .cardContent {
    // padding-right: 24px;
    flex-grow: inherit;
    height: 100%;

    &:last-child {
      padding-bottom: 16px;
    }
  }

  .noPadding {
    padding: 0 !important;
  }
}
