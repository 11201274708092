.wrapper {
    width: 100%;
    padding: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #efefef;
}

.unit {
    background-color: white;
    margin-bottom: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.head {
    margin: 16px 16px 21px;
    display: flex;
    flex-direction: row;
    min-height: 45px;
    align-items: center;
    font-weight: 500;
    font-size: 20px;
}

.body {
    margin: 16px 16px 21px;
    display: flex;
    flex-direction: row;
    height: 100%;
    flex-wrap: wrap;
    justify-content: center;
}

.status {
    width: 40%;
    height: 100px;
    border-radius: 6px !important;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25) !important;
    margin: 20px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    font-size: 18px;
}

img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
}
