// .item{
//   border-bottom: 1px solid #d9d9d9;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   flex-wrap: nowrap;

  .picture{
    overflow: hidden;
    border-radius: 3px;
    text-align: center;
    width: auto;
    min-width: 100px;
    height: 100px;
    margin: 10px 0 10px 0;
    display: flex;
    position: relative;
    cursor: pointer;
    background-color: whitesmoke;

    .noDataMess {
      margin: auto;
    }

    .grz{
      position: absolute;
    //   padding: 5px;
      background-size: cover;
      background-position: center;
      width: 80%;
      height: auto;
      bottom: 0;
      right: 0;
      transition: 500ms;
    }

    .speed{
      position: absolute;
      padding: 5px;
      background-color: white;
      border-bottom-right-radius: 5px;
      top: 0;
      left: 0;
      transition: 500ms;
    }

    > img {
      height: 100%;
      width: 100%;
      display: block;
      margin: auto;
    }
  }

  .icons{
    display: flex;
  }

  .event{
    // display: flex;
    // flex-wrap: wrap;
    // width: 800px;
    // max-width: 100%;
    flex: 1;
    padding-right: 1rem;
    > div{
      // padding: 5px 5px 5px 0;
      // width: 100%;
      // max-width: 400px;
    }
    &__title{
      font-weight: 700;
      margin-right: 5px;
    }
  }
// }
