.wrapper {
    position: relative;
    &.vertical{
        width: 50%;
    }
}

.verticalBlock {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    & > * { 
        margin-bottom: 0.5rem !important;
    }
    &:first-child {
        margin-top: 0;
    }
    &:last-child {
        margin-bottom: 0 !important;
    }
}

.filterWrapper {
    position: relative;
    &.filterVisible {
        background-color: #fff;
        border-radius: 4px;
        padding: 0 1rem;
        margin: 0 0 0.5rem 0;
        border: 1px solid rgb(221 221 221);
        &.intab {
            padding: 0.5rem 0 0;
            z-index: 3;
            border: none;
            border-top: 1px solid rgb(221 221 221);
        }
        &.vertical {
            border: none;
        }
    }
    & .visibleWrap {
        padding: 0;
        position: relative;
    }
    & .wrapperTab {
        padding: 1rem 0 0;
    }
    .visibleMode {
        width: 100%;
        top: 0;
        left: 0;
        background-color: #fff;
        z-index: 4;
        border-radius: 4px;
        &.intab {
            // padding: 0.5rem 0 0;
            z-index: 3;
            // border-bottom: 1px solid rgb(221 221 221);
        }
    }
    .verticalMode {
        // width: 50%;
        border: 1px solid rgb(221 221 221);
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        align-items: flex-start;
        padding:0.5rem 1rem;
        & > * {
            width: 100%;
        }
        & > *:not(:last-child) {
            margin-bottom: 0.8rem;
        }
        .visible {
            display: flex;
            flex-direction: column;
            justify-content: stretch;
            padding-top: 30px;
            // & > *:not(:first-child) {
            //     margin-top: 0.5rem;
            // }
        }
    }
}
.searchButton {
    margin-top: 0.5rem;
    align-self: flex-start;
    min-width: 15%;
    display: flex;
    justify-content: flex-end;
}
.hiddenMode {
    display: none !important;
}
.filter {
    text-align: right;
    position: absolute;
    top: -2rem;
    right:  0.6rem;
    &.intab {
        position: relative;
        top: -.4rem;
    }
    &.visible {
        // position: relative;
        // right: -0.6rem;
        // border-radius: 0;
        top: -1.3rem;
        .icon {
            background-color: #fff;
            border: 1px solid rgb(221 221 221);
            border-bottom: 1px solid #fff;
            z-index: 10;
            border-radius: 4px 4px 0 0;
        }

        &.intab {
            top: 0.1rem;
        }
        // border-bottom: 1px solid rgb(221 221 221);
    }
}

.saveIcon {
    width: 22px;
    height: auto;
    margin-left: 1rem;
    padding: 6px 0;
}
.buttonsWrap {
    display: flex;
    padding: 0;
    flex: 1 0 auto;
    align-items: center;
    justify-content: flex-start;
    & > *:not(:first-child) {
        margin-left: 0.5rem;
    }
}
.icon {
    min-width: 30px;
    padding: 0;
    font-size: 1rem;
}
.openText {
    color: #3399fe;
    font-size: 0.875rem;
    margin-right: 8px;
    padding-right: 0;
    font-weight: 400;
}
.badge {
    left: 1rem
}
.badgeBlock {
    width: 2rem;
    height: 0;
}
.badgeSmall {
    height: 14px;
    min-width: 14px;
    font-size: 0.5rem;
    padding: 0;
}

.horizontal {
    display: grid;
    align-items: flex-start;
    grid-row-gap: 0.5rem;
    grid-column-gap: 0.6rem;
    padding: 0;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); // автоматический подсчет колонок исходя из 300px
    &.grid-template-columns-2 { // две колонки
        grid-template-columns: repeat(2, minmax(200px, 1fr));
    }
    &.grid-template-columns-3 {// три колонки
        grid-template-columns: repeat(3, minmax(200px, 1fr));
    }
    &.grid-template-columns-4 {// четыре колонки
        grid-template-columns: repeat(4, minmax(200px, 1fr));
    }
    &.grid-template-columns-5 {// пять колонки
        grid-template-columns: repeat(5, minmax(200px, 1fr));
    }
    :global(.grid-column-1-2) { // ээлемент занимает ячейки с 1 по 2
        grid-column: 1 / 3;
    }
    :global(.grid-column-1-3) { // элемент занимает ячейки с 1 по 3
        grid-column: 1 / 4;
    }
    :global(.grid-column-1-4) { // элемент занимает ячейки с 1 по 4
        grid-column: 1 / 5;
    }
    :global(.grid-column-1-5) { // элемент занимает ячейки с 1 по 5
        grid-column: 1 / 6;
    }
    :global(.grid-column-2-3) { // элемент занимает ячейки с 2 по 3
        grid-column: 2 / 4;
    }
    :global(.grid-column-2-4) { // элемент занимает ячейки с 2 по 4
        grid-column: 2 / 5;
    }
    :global(.grid-column-2-5) { // элемент занимает ячейки с 2 по 5
        grid-column: 2 / 6;
    }
    :global(.grid-column-3-4) { // элемент занимает ячейки с 3 по 4
        grid-column: 3 / 5;
    }
    :global(.grid-column-3-5) { // элемент занимает ячейки с 3 по 5
        grid-column: 3 / 6;
    }

}
