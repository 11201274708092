.msc-wrapper {
  margin-top: 55px !important;
}

.map-finder-control {
    position: relative;
    width: 300px;

  .field {
    padding: 0 1rem .5rem;
    display: flex;
  }

    label {
      background: #fff;

      &[class*="shrink"] {
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    .textBlock {
      background-color: #fff;

      &.active {
        //width: 500px;

        input {
          width: 100%;
        }
      }

      .MuiOutlinedInput-root {
        border-radius: 0 !important;
      }
    }

    &_list {
      background: #fff;
      position: absolute;
      top: 100%;
      left:0;
      right:0;
      z-index: 9999;

      &_item {
        display: block;
      }
    }
}