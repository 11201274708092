.wrap {
    display: flex;
    justify-content: space-between;
}

.container {
    width: 70%;
}

.ratingBlock {
    background-color: #fff;
    padding: 40px;
    border-radius: 4px;
}

.customStyles {
    background-color: #f5f5f5;
    overflow-y: hidden;
}

.leftButton {
    width: 170px;
    height: 50px;
    cursor: default;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.rightButton {
    width: 170px;
    height: 50px;
    cursor: default;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.buttonsBlock {
    display: flex;
    text-align: center;
    margin-top: 10px;
}

.text {
    margin-left: 10px;
    color: #319afe;
    font-weight: bold;
    font-size: 28px;
}

.noText {
    margin-left: 10px;
    color: #fd1f1e;
    font-weight: bold;
    font-size: 28px;
}
