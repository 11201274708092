@import './src/css/media-mixin';

.videoFixation{
  width: 99%;
  margin: auto;
}

.listItem{
  border-bottom: 1px solid #d9d9d9;

  &__content{
    width: 100%;
  }

  &__text_block{
    display: flex;
    flex-wrap: wrap;
    &:first-child {
      margin-bottom: 10px;
    }

    .text{
      word-break: break-word;
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }

      span{
        font-weight: 500;
      }
    }
  }
}

.addCameraBtn {
  display: flex;
  & > div {
    width: 100%;
  }
  & > button {
    min-width: 40px;
    height: 40px;
    margin-left: 10px;
  }
}

.tsNumField{
  display: flex;
  width: 100%;
  align-items: center;
  height: fit-content;
  & > div {
    width: 100%;
  }
  .field{
    width: 100%;
  }
  .btn{
    margin-left: 10px;
    width: 40px;
    height: 40px;
    align-self: center;
  }
}

.materialSelect{
  &__label{
    transition: 200ms;
    position: absolute;
    z-index: 10;
    color: #757575;
    transform: translate(14px, 50%);
    background-color: white;
    padding: 0 5px 0 3px;
    cursor: text;
}
}

.mapIcon{
  background-color: inherit;
  .mapIconDiv{
    border: 3px solid white;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    position: relative;
  }
  img{
    padding: 4px;
    position: absolute;
    top: 0;
    left: -8px;
    right: 0;
    bottom: 0;
    margin: auto;
  }
}

