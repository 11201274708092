.digitalTwin {
    &.markerIcon {
        display: flex;
        height: 100%;
        border-radius: 50%;
        align-items: center;
        text-align: center;
        justify-content: center;
        i {
            color: #fff;
            font-size: 1rem;
        }
    }
}
