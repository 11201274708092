.sort-checkbox {
  .name {
    margin-right: 1rem;
    font-weight: bold;
  }
  .name-size {
  }
  .item {
    margin-right: 1rem;
    &:last-child {
      margin-right: 0;
    }
  }
}