.statistic-header-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding: 0 .5rem;

  @media (max-width: 900px) {
    justify-content: inherit;
    flex-direction: column;
  }

  img {
    margin: 0;
  }
  .item {
    position: relative;

    @media (max-width: 900px) {
      text-align: center;
      margin-bottom: .5rem
    }

    i {
      margin-right: .5rem;
    }
  }

  // .separator {
  //   margin-right: 1rem !important;

  //   &::after {
  //     content: "";
  //     background: #34ccfe;
  //     width: 3px;
  //     top: -5px;
  //     bottom: -5px;
  //     position: absolute;
  //     left: 0;
  //   }
  // }

  .logout {
    i {
      color: #34ccfe;
      margin: 0;
    }
  }

}
.separator {
  
  background: #34ccfe;
  width: 3px;
  height: 60%;
}